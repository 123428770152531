import React, { Fragment, useLayoutEffect, useState, useRef } from 'react';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

import { SmallDotGroup } from '../assets/shapes/shape';
import BgAnimation from './BgAnimation';
import SocialMedia from './SocialMedia';
import gsap from 'gsap';
import { Power2 } from 'gsap';
import Careers from './careers/Careers';
const BannerSmall = ({ image, styleClass, headding }) => {
  const tl = gsap.timeline();
  let root = useRef(null);
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      tl.from(
        '.position-relative',
        {
          opacity: 0,
          ease: Power2.easeIn,
        },
        '0.1'
      )

        .to('.headH1', { y: 10, opacity: 1 }, 0.3)
        .to('.image-wrapper', { opacity: 1, ease: Power2.easeIn }, '0.4');
      tl.to(
        '.sm-banner-btn',
        {
          opacity: 1,
          ease: Power2.easeIn,
        },
        '0.1'
      );
    }, root);
    return () => ctx.revert();
  }, []);

  const [visibleRight, setVisibleRight] = useState(false);
  const handleCareer = () => {
    document.body.classList.add('scroll-prevent');
    setVisibleRight(true);
  };
  const modalHidden = () => {
    setVisibleRight(false);
    document.body.classList.remove('scroll-prevent');
  };
  return (
    <Fragment>
      <Careers visibleRight={visibleRight} modalHidden={modalHidden} />

      <div className='smallBanner-wrapper' ref={root}>
        <div className='position-relative zindex-0'>
          <BgAnimation />
        </div>
        <div className={`${styleClass} image-wrapper`}>
          <img src={image} alt='#' className="img-fluid" />
        </div>
        <div className='container'>
          <div className='ml-3'>
            <SmallDotGroup />
          </div>
          <header>
            <h2 className='headH1 z-index-1 opacity-0'>{headding}</h2>
          </header>
          <div className='position-relative left-100'>
            <SmallDotGroup />
          </div>
          <button onClick={handleCareer} className='sm-banner-btn btn'>
            Lets work together →
          </button>
        </div>
        <div className='container'>
          <SocialMedia />
        </div>
        <div className='position-relative zindex-0'>
          <BgAnimation />
        </div>
      </div>
    </Fragment>
  );
};

export default BannerSmall;
