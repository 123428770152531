import React, { Fragment } from 'react';
import { OurPartnerHead } from '../../assets/shapes/shape';
import ourPartner from '../../assets/images/our_partners.png';
const AboutOurPartners = () => {
  return (
    <Fragment>
      <div className='gap'>
        <div className='container gap-bottom'>
          <div className='d-flex'>
            <header className='m-auto'>
              <OurPartnerHead />
            </header>
          </div>
          <div className='mt-4'>
            <figure>
              <img className='img-fluid' src={ourPartner} alt='#' />
            </figure>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutOurPartners;
