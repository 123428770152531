import React, { useRef, Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { Power4 } from 'gsap';
import axios from 'axios';
import dots from '../../assets/images/dots.png';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import './blog.css';

gsap.registerPlugin(ScrollTrigger);

const BlogDetailedView = () => {
  const [modal, setModal] = useState(false);
  const movingBg = useRef(null);
  const movingBgLight = useRef(null);
  const modalWrapper = useRef(null);
  const scrollVisible = useRef(null);
  const tl = useRef(null);
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current
      .to(movingBg.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(movingBgLight.current, {
        width: '100%',
        duration: 0.5,
        ease: Power4.easeInOut,
      })
      .to(modalWrapper.current, {
        opacity: 1,
        duration: 0.5,
        ease: Power4.easeInOut,
      });

    setModal(tl.current.play());
  }, []);

  useEffect(() => {
    modal ? tl.current.play() : tl.current.reverse();
  }, [modal]);

  useEffect(() => {
    const showAnim = gsap
      .from('.back', {
        yPercent: -150,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: 'top top',
      end: 9999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }, []);

  const modalVisible = () => {
    setModal(!modal);
    setTimeout(() => {
      navigate('/blogs');
    }, 1600);
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          'https://strappy.gbs-plus.com/api/blogs?populate=*'
        );
        const blogs = response?.data?.data || [];

        const blogMatch = blogs.find((blog) => String(blog.id) === String(id));

        setBlog(blogMatch);
      } catch (error) {
        console.error(error);
      }
    };

    fetchBlog();
  }, [id]);

  const renderTextContent = (children) => {
    return children.map((child, index) => {
      let text = child?.text || '';
      let content = text;

      if (child?.bold) {
        content = <strong key={index}>{text}</strong>;
      }
      if (child?.italic) {
        content = <em key={index}>{text}</em>;
      }
      if (child?.underline) {
        content = <u key={index}>{text}</u>;
      }
      if (child?.strikethrough) {
        content = <del key={index}>{text}</del>;
      }
      if (child?.code) {
        content = (
          <p className='bg-dark text-white p-3 rounded w-100' key={index}>
            {text}
          </p>
        );
      }

      return content;
    });
  };

  const renderParagraph = (item, index) => {
    return (
      <p key={index} className='mt-3 w-100'>
        {renderTextContent(item?.children)}
      </p>
    );
  };

  const renderList = (item, index) => {
    const ListTag = item.format === 'ordered' ? 'ol' : 'ul';
    return (
      <div key={index} className='full-width-list'>
        <ListTag className='mt-3'>
          {item.children.map((listItem, idx) => (
            <li key={idx}>{renderTextContent(listItem?.children)}</li>
          ))}
        </ListTag>
      </div>
    );
  };

  const renderCode = (item, index) => {
    const codeText = renderTextContent(item?.children);

    const handleCopyClick = () => {
      const textToCopy = codeText.props.children;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          alert('Code copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy code: ', err);
        });
    };

    return (
      <div key={index} className='full-width-list'>
        <div className='code-container'>
          <pre>{codeText}</pre>
          <button
            onClick={handleCopyClick}
            className='btn btn-primary copy-btn'
          >
            Copy Code
          </button>
        </div>
      </div>
    );
  };

  const renderBlogContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case 'paragraph':
          return renderParagraph(item, index);
        case 'list':
          return renderList(item, index);
        case 'code':
          return renderCode(item, index);
        default:
          return null;
      }
    });
  };

  return (
    <Fragment>
      <div ref={movingBg} className='moving-bg'></div>
      <div ref={movingBgLight} className='moving-bg-light'></div>
      <div ref={modalWrapper} className='modal-wrapper gap-4'>
        <div className='case_study_content1 mt-5'>
          <div className='container'>
            <div className='back' ref={scrollVisible}>
              <button onClick={modalVisible} className='btn btn-link'>
                ⟵ Back to Blog
              </button>
            </div>
          </div>
          <div className='container'>
            {blog ? (
              <div className='row'>
                <div className='col-lg-8 mx-auto bg-white p-5 rounded'>
                  <div className='blog-section mb-3'>
                    <img className='dots-img' src={dots} alt='' />
                    <h1>{blog.Title}</h1>
                  </div>

                  <div className='image_wrapper mb-3'>
                    <img
                      className='img-fluid d-block rounded detail-img mt-4'
                      loading='lazy'
                      src={`https://strappy.gbs-plus.com${blog.Media?.url}`}
                      // src={`https://gbs-website.work.gd${blog.Media?.url}`}
                      alt={blog.id}
                    />
                    <div className='mt-3 date-section font-5'>
                      <i className='pi pi-calendar'></i>{' '}
                      {new Date(blog?.createdAt).toLocaleDateString('en-US')}
                    </div>
                  </div>

                  <div className='p_content'>
                    {renderBlogContent(blog.content)}
                  </div>
                </div>
              </div>
            ) : (
              <div className='loading-skeleton'>
                <Skeleton height={50} width={250} className='mb-4' />
                <Skeleton height={200} className='mb-4' />
                <Skeleton count={5} className='mb-4' />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BlogDetailedView;
