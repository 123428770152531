const menuData = [
  {
    menuName: 'Home',
    path: '/',
  },
  {
    menuName: 'About',
    path: '/about',
  },
  {
    menuName: 'Offerings',
    path: '/offerings',
  },
  {
    menuName: 'Platforms',
    path: '/platforms',
  },
  {
    menuName: 'Clients',
    path: '/clients',
  },
  {
    menuName: 'Case Studies',
    path: '/case-studies',
  },
  {
    menuName: 'Blog',
    path: '/blogs',
  },
  {
    menuName: 'Contact Us',
    path: '/contact-us',
  },
];
export default menuData;
